function formValidation( settingsCustom ) {

  // Настройки по умолчанию
  let settingsDefault = {
    classForms:      'js-form', // Класс форм
    classHolders:    'js-control-holder', // Класс Control Holder
    classCheck:      'js-control-check', // Класс Control Check
    classSelect:     'js-control-select', // Класс Control Select
    showLabels:      true, // Показывать лейблы над полем ввода
    classLabels:     'js-label', // Класс лейблов
    classInputs:     'js-form-control', // Класс инпутов
    classSubmit:     'js-submit', // Класс кнопки submit
    validation:      true, // Валидировать форму, если атрибут не указан
    showErrors:      true, // Показывать ошибки под полем ввода
    requiredOne:     false, // Если true, то при заполнении одного из полей e-mail или телефон, второе заполнять не обязательно
    errorEmpty:      'This field is required!', // Ошибка, если поле пустое
    errorLength:     'Please enter more 1 character!', // Ошибка, если недостаточно символов
    errorEmail:      'Incorrect e-mail address!', // Ошибка, если некорректный e-mail
    errorPhone:      'Incorrect phone number!', // Ошибка, если некорректный номер телефона,
    errorCheck:      'Please select one button!', // Ошибка, если не выбрана радио-кнопка или чекбокс
    errorSelect:     'Please select one item!', // Ошибка, если не выбран пункт выпадающего списка
    errorSend:       'Message not sent!', // Текст сообщения ошибки отправки
    successSend:     'Message sent successfully!', // Текст сообщения успешной отправки
    emailMask:       /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, // Маска для e-mail'a
    phoneMinLength:  5, // Минимальное количество цифр в номере (без учёта символов и пробелов)
    phoneMaskBefore: /[\/!@#$%^&*/|_=`.~;":'a-zA-Zа-яА-Я\[\]\ ]/, // Недопустимые символы в номере телефона
    phoneMaskAfter:  /[\/!@#$%^&*+()/|_~=`.;":'a-zA-Zа-яА-Я\[\]\-\ ]/, // Символы в номере телефона, которые не будут учитываться при подсчёте количества цифр
    ajax:            false, // Включить аяксовую отправку
    fileURL:         '../', // Путь к папке с фалом обработчика
    fileName:        'feedback', // Имя файла, который будет принимать данные
    action:          '', // Для кастомной функции в Wordpress
    writeLog:        false // Выводить логи
  }

  // Объект, в который попадут соединённые настройки
  let settings = {};

  /*
   * Берём объект settingsDefault
   * Расширяем / заменяем его параметры объектом settingsCustom
   * Записываем это всё в объект settings
   */
  $.extend( settings, settingsDefault, settingsCustom );

  // Объект, в котором изменяется статус инпутов
  let statusFields = {
    hasErrors: true // По-умолчанию, есть ошибки (чтобы форма не отправилась до проверки)
  };

  let allForms      = $('.'+settings.classForms), // Выбираем селектор форм
      controlHolder = $('.'+settings.classHolders); // Работаем дальше с селектором Control Holder

  // Если на странице есть формы
  if ( allForms.length ) {

    // Выводим формы в цикле
    allForms.each( function() {
      let validationAttr = $(this).data('validation');

      if ( !validationAttr ) validationAttr = settings.validation;

      // Если форма должна валидироваться
      if ( validationAttr ) {
        let formControls = $(this).find('.'+settings.classInputs), // Все инпуты в форме
            btnSubmit    = $(this).find('.'+settings.classSubmit), // Кнопка Submit
            allLabels    = $(this).find('.'+settings.classLabels), // Все лейблы в форме
            formCheck    = $(this).find('.'+settings.classCheck),  // Поля с радио-кнопками или чекбоксами
            formSelect   = $(this).find('.'+settings.classSelect), // Поля с селектами
            fileURL      = $(this).data('url');

        if ( fileURL ) settings.fileURL = fileURL; // Путь к папке с фалом обработчика

        // 1. Если есть лейблы внутри формы, скрываем их, если так указано в настройках
        if ( allLabels.length && !settings.showLabels ) allLabels.hide();

        // 2. Отслеживаем нажатые клавишы в форме
        $(this).on( 'keypress', function( e ) {

          // Если нажат Enter
          if ( e.keyCode == 13 ) {
            e.preventDefault();

            btnSubmit.click(); // Кликаем по кнопке Submit

          }
        } );

        // 3. Если в форме есть инпуты
        if ( formControls.length ) {

          // Выводим инпуты в цикле
          formControls.each( function() {
            let thisInput       = $(this), // Обращаемся к this (инпуту) внутри всей функции
                inputLabel      = thisInput.closest(controlHolder).find('.'+settings.classLabels), // Лейбл каждого инпута
                requiredControl = thisInput.attr('data-control'), // Какая проверка должна быть в поле ввода
                action; // При каком действии выполнять проверку (change или keyup)

            // Если инпут обязателен для заполнения
            if ( $(this).attr('data-required') === 'true' ) {

              // Если есть лейбл внутри Control Holder и его надо показывать (настройки)
              if ( inputLabel.length && settings.showLabels === true ) {

                // Добавляем звёздочку в текст лейбла обязательных полей
                inputLabel.each( function() {
                  let labelText       = $(this).text(),
                      changeLabelText = labelText.replace(':', ''),
                      requiredSymbol  = '<span class="required">*</span>';

                  // Если в лейбле было двоеточие, ставим его за звёздочкой
                  if ( labelText.match(':') ) {

                    $(this).html( changeLabelText + requiredSymbol + ':' );

                  } else {

                    $(this).html( changeLabelText + requiredSymbol );

                  }

                } );

              }

              // Проверяем поле на наличие ошибок
              thisInput.on( 'keydown', function() {

                if ( $(this).closest(controlHolder).hasClass('has-error') ) action = 'keyup';

              } );

              // Проверяем поле на наличие ошибок
              thisInput.on( 'keyup', function() {

                requiredOne( $(this) );

              } );

              // Отслеживаем изменение поля после потери фокуса
              thisInput.on( 'change', function() {

                // Если инпут проверяется на длинну
                if ( requiredControl === 'length' && $(this).filter("[data-reqired='true']") ) {

                  controlLength( $(this) );

                } else if ( requiredControl === 'email' ) {
                  // Если инпут проверяется на корректность E-mail'а

                  controlEmail( $(this) );

                } else if ( requiredControl === 'phone' ) {
                  // Если инпут проверяется на корректность ввода номера

                  controlPhone( $(this) );

                }

                if ( $('[data-required="false"]').closest(controlHolder).hasClass('has-error') ) {

                  $('[data-required="false"]').closest(controlHolder).removeClass('has-error');
                  $('[data-required="false"]').closest(controlHolder).find('.box-error').remove();

                }

              } );

              // Отслеживаем изменение поля во время ввода, если до этого была ошибка
              thisInput.on( 'keyup', function() {

                if ( requiredControl === 'phone' ) replacePhoneNumber( $(this) );

                if ( action === 'keyup' && $(this).filter("[data-reqired='true']") ) {

                  // Если инпут проверяется на длинну
                  if ( requiredControl === 'length' ) {

                    controlLength( $(this) );

                  } else if ( requiredControl === 'email' ) {
                    // Если инпут проверяется на корректность E-mail'а

                    controlEmail( $(this) );

                  } else if ( requiredControl === 'phone' ) {
                    // Если инпут проверяется на корректность ввода номера

                    controlPhone( $(this) );

                  }

                }

              } );

            }

          } );

        }

        // 4. Если в форме есть поля с радио-кнопками или чекбоксами
        if ( formCheck.length ) {

          // Выводим поля с радио-кнопками или чекбоксами в цикле
          formCheck.each( function() {
            let thisControl = $(this), // Обращаемся к this (инпуту) внутри всей функции
                inputLabel  = thisControl.closest(controlHolder).find('.'+settings.classLabels); // Лейбл каждого инпута

            // Если инпут обязателен для заполнения
            if ( $(this).attr('data-required') === 'true' && $(this).attr('data-control') === 'check' ) {

              // Если есть лейбл внутри Control Holder и его надо показывать (настройки)
              if ( inputLabel.length && settings.showLabels === true ) {

                // Добавляем звёздочку в текст лейбла обязательных полей
                inputLabel.each( function() {
                  let labelText       = $(this).text(),
                      changeLabelText = labelText.replace(':', ''),
                      requiredSymbol  = '<span class="required">*</span>';

                  // Если в лейбле было двоеточие, ставим его за звёздочкой
                  if ( labelText.match(':') ) {

                    $(this).html( changeLabelText + requiredSymbol + ':' );

                  } else {

                    $(this).html( changeLabelText + requiredSymbol );

                  }

                } );

              }

              $(this).on( 'change', function() {
                controlCheck( $(this) );
              } );

            }

          } );

        }

        // 5. Если в форме есть поля с выпадающим списком
        if ( formSelect.length ) {

          // Выводим поля с выпадающим списком в цикле
          formSelect.each( function() {
            let thisControl = $(this), // Обращаемся к this (инпуту) внутри всей функции
                inputLabel  = thisControl.closest(controlHolder).find('.'+settings.classLabels); // Лейбл каждого инпута

            // Если инпут обязателен для заполнения
            if ( $(this).attr('data-required') === 'true' ) {

              // Если есть лейбл внутри Control Holder и его надо показывать (настройки)
              if ( inputLabel.length && settings.showLabels === true ) {

                // Добавляем звёздочку в текст лейбла обязательных полей
                inputLabel.each( function() {
                  let labelText       = $(this).text(),
                      changeLabelText = labelText.replace(':', ''),
                      requiredSymbol  = '<span class="required">*</span>';

                  // Если в лейбле было двоеточие, ставим его за звёздочкой
                  if ( labelText.match(':') ) {

                    $(this).html( changeLabelText + requiredSymbol + ':' );

                  } else {

                    $(this).html( changeLabelText + requiredSymbol );

                  }

                } );

              }

            }

          } );

        }

        // 6. Отменяем отправку формы, делаем проверку и если всё хорошо, запускаем Ajax
        btnSubmit.on( 'click', function( e ) {

          let thisForm    = $(this).closest('.'+settings.classForms),
              countErrors = thisForm.find(controlHolder).find('.'+settings.classInputs+'[data-required="true"]');

          // Выводим инпуты в цикле и запускаем проверки
          formControls.each( function() {
            let requiredStatus  = $(this).attr('data-required'),
                requiredControl = $(this).attr('data-control');

            // Если инпут должен валидироваться
            if ( requiredStatus === 'true' ) {

              // Если инпут проверяется на длинну
              if ( requiredControl === 'length' ) controlLength( $(this) );

              // Если инпут проверяется на корректность E-mail'а
              if ( requiredControl === 'email' ) controlEmail( $(this) );

              // Если инпут проверяется на корректность ввода номера
              if ( requiredControl === 'phone' ) controlPhone( $(this) );

            }

          } );

          // Выводим поля с радио-кнопками или чекбоксами в цикле и запускаем проверки
          formCheck.each( function() {
            let requiredStatus  = $(this).attr('data-required'),
                requiredControl = $(this).attr('data-control');

            // Если поле должно валидироваться и выбраны ли радио-кнопка или чекбокс
            if ( requiredStatus === 'true' && requiredControl === 'check' ) controlCheck( $(this) );

          } );

          // Выводим поля с выдающим списком в цикле и запускаем проверки
          formSelect.each( function() {
            let requiredStatus  = $(this).attr('data-required'),
                requiredControl = $(this).attr('data-control');

            // Если поле должно валидироваться и выбран ли пункт выпадающего списка
            if ( requiredStatus === 'true' && requiredControl === 'select' ) controlSelect( $(this) );

          } );

          // Если у инпутов нет ошибок, записываем новый статус в объект statusFields
          if ( !countErrors.closest(controlHolder).hasClass('has-error') ) {

            statusFields = {
              hasErrors: false
            };

          } else {

            e.preventDefault();

          }

          // Проверяем объект statusFields, если ошибок нет, то продолжаем отправку формы
          if ( !statusFields.hasErrors ) {

            if ( settings.ajax ) {
              e.preventDefault();

              let completeMessage,
                  completeClass,
                  actionPost;

              if ( settings.action ) actionPost = `?action=${settings.action}`;

              writeLog( thisForm );

              $.ajax( {

                url: `${window.location.origin}/${settings.fileURL}/${settings.fileName}.php${actionPost}`,
                type: 'POST',
                data: thisForm.serialize(),

                error: function() {
                  completeMessage = settings.errorSend;
                  completeClass = 'sent-error';
                },

                success: function() {
                  completeMessage = settings.successSend;
                  completeClass = 'sent-success';
                },

                complete: function() {

                  thisForm.find('div').filter(':first').before( function() {

                    return `<div class="box-message ${completeClass}">${completeMessage}</div>`;

                  } );

                  setTimeout( function() {

                    thisForm.find('.box-message').remove();

                  }, 5000);

                  formControls.val('');
                  formCheck.find(formControls).prop('checked', false);
                  formSelect.find(formControls).prop('selectedIndex', 0);

                  statusFields.hasErrors = true;

                }

              } );

            } else {

              thisForm.submit();

            }

          }

        } );

      }

    } );

  }

  /*
   * Функции обработки полей ввода
   */

  // Проверка поля на длинну
  let controlLength = function( thisObj ) {
    let boxError = $('.box-error');

    // Если поле пустое
    if ( thisObj.val().length === 0 ) {

      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.after( function() {

          thisObj.closest(controlHolder).find(boxError).remove();
          return '<div class="box-error">' + settings.errorEmpty + '</div>';

        } );

      }

    } else if ( thisObj.val().length > 0 && thisObj.val().length < 2 ) {
      // Если в поле меньше 2-х символов

      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.after( function() {

          thisObj.closest(controlHolder).find(boxError).remove();
          return '<div class="box-error">' + settings.errorLength + '</div>';

        } );

      }

    } else {

      thisObj.closest(controlHolder).removeClass('has-error');

      if ( settings.showErrors === true ) {

        thisObj.closest(controlHolder).find(boxError).remove();

      }

    }

  }

  // Проверка поля на корректность E-mail
  let controlEmail = function( thisObj ) {
    let boxError  = $('.box-error');

    // Если поле пустое
    if ( thisObj.val().length === 0 ) {

      // Если у Control Holder уже есть ошибка, то не надо добавлять класс ещё раз
      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.closest(controlHolder).find(boxError).remove();

        thisObj.after( function() {

          return '<div class="box-error">'+settings.errorEmpty+'</div>';

        } );

      }

    } else if ( thisObj.val().length > 0 && settings.emailMask.test( thisObj.val() ) === false ) {
      // Если в поле меньше 2-х символов

      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.closest(controlHolder).find(boxError).remove();

        thisObj.after( function() {
          return '<div class="box-error">'+settings.errorEmail+'</div>';

        } );

      }

    } else {

      thisObj.closest(controlHolder).removeClass('has-error');

      if ( settings.showErrors === true ) {

        thisObj.closest(controlHolder).find(boxError).remove();

      }

    }

  }

  // Проверка поля ввода номера телефона в реальном времени
  let replacePhoneNumber = function( thisObj ) {
    let thisValue = thisObj.val(),
        phoneMask = settings.phoneMaskBefore;

    if ( phoneMask.test(thisValue) ) {
      thisValue = thisValue.replace(phoneMask, '');
      thisObj.val(thisValue);
    }

  }

  // Проверка поля на корректность номера телефона
  let controlPhone = function( thisObj ) {
    let thisValue = thisObj.val(),
        boxError  = $('.box-error'),
        phoneMask = settings.phoneMaskAfter,
        checkValue;

    thisValue  = thisValue.replace(phoneMask, '');
    checkValue = thisValue;

    // Если поле пустое
    if ( thisValue.length === 0 ) {

      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.after( function() {

          thisObj.closest(controlHolder).find(boxError).remove();
          return '<div class="box-error">'+settings.errorEmpty+'</div>';

        } );

      }

    } else if ( thisValue.length > 0 && checkValue.length < settings.phoneMinLength ) {

      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.after( function() {

          thisObj.closest(controlHolder).find(boxError).remove();
          return '<div class="box-error">'+settings.errorPhone+'</div>';

        } );

      }

    } else {

      thisObj.closest(controlHolder).removeClass('has-error');

      if ( settings.showErrors === true ) {

        thisObj.closest(controlHolder).find(boxError).remove();

      }

    }

  }

  // Функция проверки обязательного заполнения одного из полей (телефон или e-mail), если указано в настройках
  let requiredOne = function( thisObj ) {
    let trueEmail  = $('[data-required="true"][data-control="email"]'), // Поле e-mail, которое должно валидироваться
        falseEmail = $('[data-required="false"][data-control="email"]'), // Поле e-mail без проверки
        truePhone  = $('[data-required="true"][data-control="phone"]'), // Поле телефон, которое должно валидироваться
        falsePhone = $('[data-required="false"][data-control="phone"]'); // Поле телефон без проверки

    // Если в настройках указано, что при заполнении поля E-mail или Телефон, второе заполнять не обязательно
    if ( settings.requiredOne === true ) {
      let trueThis,
          trueAnother,
          falseAnother,
          hasError,
          checkValue;

      // Если сейчас редактируется поле E-mail
      if ( thisObj.filter("[data-control='email']").length ) {

        trueThis     = trueEmail,
        trueAnother  = truePhone,
        falseAnother = falsePhone;

      } else if ( thisObj.filter("[data-control='phone']").length ) {
        // Если сейчас редактируется поле Телефон

        trueThis     = truePhone,
        trueAnother  = trueEmail,
        falseAnother = falseEmail,
        checkValue   = thisObj.val().replace(settings.phoneMaskAfter, '');

      }

      if ( trueThis.length && thisObj.filter("[data-required='true']") ) {

        if ( thisObj.val().length >= 0 && thisObj.is(':focus') ) {

          if ( thisObj.filter("[data-control='email']").length ) {

            if ( settings.emailMask.test( thisObj.val() ) === false ) hasError = true;

          } else if ( thisObj.filter("[data-control='phone']").length ) {

            if ( checkValue.length < settings.phoneMinLength ) hasError = true;

          }

        }

        if ( hasError != true ) {
          let label      = trueAnother.closest(controlHolder).find('.'+settings.classLabels),
              labelText  = label.text(),
              changeText = labelText.replace('*', '');

          trueAnother.attr('data-required', 'false');

          label.html(changeText);
          trueAnother.closest(controlHolder).removeClass('has-error');
          trueAnother.closest(controlHolder).find('.box-error').remove();
          falseAnother.closest(controlHolder).removeClass('has-error');
          falseAnother.closest(controlHolder).find('.box-error').remove();

        } else if ( falseAnother.length && hasError != false ) {
          let labelText       = falseAnother.closest(controlHolder).find('.'+settings.classLabels).text(),
              changeLabelText = labelText.replace(':', ''),
              requiredSymbol  = '<span class="required">*</span>';

          falseAnother.attr('data-required', 'true');
          trueAnother.closest(controlHolder).removeClass('has-error');
          trueAnother.closest(controlHolder).find('.box-error').remove();
          falseAnother.closest(controlHolder).removeClass('has-error');
          falseAnother.closest(controlHolder).find('.box-error').remove();

          // Если в лейбле было двоеточие, ставим его за звёздочкой
          if ( labelText.match(':') ) {

            falseAnother.closest(controlHolder).find('.'+settings.classLabels).html( changeLabelText + requiredSymbol + ':' );

          } else {

            falseAnother.closest(controlHolder).find('.'+settings.classLabels).html( changeLabelText + requiredSymbol );

          }

        }

      }

      if ( $('[data-required="false"]').closest(controlHolder).hasClass('has-error') ) {

        $('[data-required="false"]').closest(controlHolder).removeClass('has-error');
        $('[data-required="false"]').closest(controlHolder).find('.box-error').remove();

      }

    }

  }

  // Проверка выбрана ли радиокнопка или чекбокс
  let controlCheck = function( thisObj ) {
    let boxError = $('.box-error');

    if ( thisObj.find('.'+settings.classInputs).is(':checked') != true ) {

      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.after( function() {

          thisObj.closest(controlHolder).find(boxError).remove();
          return '<div class="box-error">' + settings.errorCheck + '</div>';

        } );

      }

    } else {

      thisObj.closest(controlHolder).removeClass('has-error');

      if ( settings.showErrors === true ) {

        thisObj.closest(controlHolder).find(boxError).remove();

      }

    }

  }

  // Проверка выбран ли пункт меню выпадающего списка
  let controlSelect = function( thisObj ) {
    let firstOption = thisObj.find('.'+settings.classInputs).find('option').filter(':first'),
        boxError    = $('.box-error');

    if ( firstOption.is(':disabled') && firstOption.is(':selected') ) {

      if ( !thisObj.closest(controlHolder).hasClass('has-error') ) {

        thisObj.closest(controlHolder).addClass('has-error');

      }

      if ( settings.showErrors === true ) {

        thisObj.after( function() {

          thisObj.closest(controlHolder).find(boxError).remove();
          return '<div class="box-error">' + settings.errorSelect + '</div>';

        } );

      }

    } else {

      thisObj.closest(controlHolder).removeClass('has-error');

      if ( settings.showErrors === true ) {

        thisObj.closest(controlHolder).find(boxError).remove();

      }

    }

  }

  // Вывод логов
  let writeLog = function( thisForm ) {
    let logOption = settings.writeLog;

    if ( logOption == true ) console.log( thisForm.serialize() );

  }

}
